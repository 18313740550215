import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Box } from '@qga/roo-ui/components';
import { SCREEN_SIZE } from '../../../../constants';

interface Props {
  brand: 'qantas' | 'jetstar';
}

const Wrapper = styled(Box)`
  background-color: ${themeGet('components.travelDatesCalendar.background')};

  ${SCREEN_SIZE.MD} {
    border: 1px solid ${themeGet('components.travelDatesCalendar.borderColor')};
    box-shadow: soft;
    border-radius: ${(props: Props) =>
      props.brand === 'jetstar' ? '16px' : '10px'};
`;

export default Wrapper;
