import { NakedButton } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';

const ResetButton = styled(NakedButton)`
  color: ${themeGet('components.travelDatesCalendar.actionReset.color')};
  font-size: ${themeGet('components.travelDatesCalendar.actionReset.fontSize')};
  font-weight: ${themeGet(
    'components.travelDatesCalendar.actionReset.fontWeight',
  )};
  text-transform: ${themeGet(
    'components.travelDatesCalendar.actionReset.textTransform',
  )};

  &:focus,
  &:hover {
    color: ${themeGet('components.travelDatesCalendar.actionReset.colorHover')};
  }
`;

export default ResetButton;
