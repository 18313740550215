import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Box } from '@qga/roo-ui/components';
import { SCREEN_SIZE } from '../../../../constants';
import { css } from '@emotion/core';

interface Props {
  brand: 'qantas' | 'jetstar';
  border?: boolean;
}

const FooterWrapper = styled(Box)`
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 2;
  background: ${themeGet('components.travelDatesCalendar.background')};

  ${(props: Props) =>
    props.border &&
    css`
      border-top: ${themeGet(
          'components.travelDatesCalendar.actions.borderWidth',
        )(props)}
        solid
        ${themeGet('components.travelDatesCalendar.actions.borderColor')(props)};
    `}

  ${SCREEN_SIZE.LG} {
    bottom: auto;
    position: relative;
    border-top: none;
    border-radius: ${(props: Props) =>
      props.brand === 'jetstar' ? '0 0 16px 16px' : '0 0 10px 10px'};
  }
`;

export default FooterWrapper;
