import { pick, equals } from 'ramda';
import pluralize from 'pluralize';
import {
  flyingFromChangeEvent,
  travellingToChangeEvent,
  datesChangeEvent,
  travellersChangeEvent,
} from './events';
import { getTravellersCount } from '@experiences-ui/shared/utils';

type DateData = {
  departureDate?: string | undefined;
  returnDate?: string | undefined;
};

type OccupantsData = {
  adults?: number | string;
  children?: number | string;
  infants?: number | string;
};

const objectsEqual =
  <T extends {}>(keys: string[]) =>
  (a: T | undefined, b: T | undefined) => {
    if (!a && !b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    return equals(pick(keys, a), pick(keys, b));
  };

const areDatesEqual = objectsEqual(['departureDate', 'returnDate']);
const areOccupantsEqual = objectsEqual(['adults', 'children', 'infants']);

export const handleFlyingFromDatalayerEvent = (
  oldFlyingFrom: string | undefined,
  newFlyingFrom: string | undefined,
) => {
  if (newFlyingFrom && oldFlyingFrom !== newFlyingFrom) {
    flyingFromChangeEvent(newFlyingFrom);
  }
};

export const handleTravellingToDatalayerEvent = (
  oldTravellingTo: string | undefined,
  newTravellingTo: string | undefined,
) => {
  if (newTravellingTo && oldTravellingTo !== newTravellingTo) {
    travellingToChangeEvent(newTravellingTo);
  }
};

export const handleDateDataLayerEvent = (
  oldDates: DateData | undefined,
  newDates: DateData | undefined,
) => {
  if (!areDatesEqual(oldDates, newDates) && newDates) {
    datesChangeEvent(
      `${newDates.departureDate ?? ''} - ${newDates.returnDate ?? ''}`,
    );
  }
};

export const handleOccupantsDataLayerEvent = (
  oldOccupants: OccupantsData | undefined,
  newOccupants: OccupantsData | undefined,
) => {
  if (!areOccupantsEqual(oldOccupants, newOccupants) && newOccupants) {
    const travellerCount = getTravellersCount(newOccupants);
    travellersChangeEvent(pluralize('traveller', travellerCount, true));
  }
};
