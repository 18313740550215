import type { DateObj } from 'dayzed';
import styled from '@emotion/styled';
import { Flex } from '@qga/roo-ui/components';
import Day from '../Day';

const StyledWeek = styled(Flex)`
  & + & {
    margin-top: 0.25rem;
  }
`;

interface Props {
  week: Array<DateObj | ''>;
  monthIndex?: number;
}

const Week = ({ week, monthIndex = 0 }: Props) => {
  return (
    <StyledWeek>
      {week.map((day, i) => (
        <Day dateObj={day} key={i} monthIndex={monthIndex} />
      ))}
    </StyledWeek>
  );
};

export default Week;
