import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Box, Flex, Text } from '@qga/roo-ui/components';

interface Props {
  fixed?: boolean;
}

const WEEKDAY_NAMES_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const StyledWeekName = styled(Text)`
  color: ${themeGet('components.dateRangePicker.weekName.color')};
  font-size: ${themeGet('components.dateRangePicker.weekName.fontSize')};
  line-height: ${themeGet('space.5')};
`;

const MobileWrapper = styled(Flex)`
  width: 100%;
  background: ${themeGet('colors.white')};
  border-bottom: 1px solid
    ${themeGet('components.dateRangePicker.daysOfWeek.borderColor')};

  padding: 0 20.5px 0 20.5px;
  z-index: ${themeGet('zIndices.foreground')};
`;

const Days = () => (
  <Flex pb="2" mt="2" width="100%">
    {WEEKDAY_NAMES_SHORT.map((weekdayName) => (
      <Box key={weekdayName} px="2" textAlign="center" width="calc(100% / 7)">
        <StyledWeekName>{weekdayName}</StyledWeekName>
      </Box>
    ))}
  </Flex>
);

const DaysOfWeek: React.FC<Props> = ({ fixed = false }) => {
  if (fixed) {
    return (
      <MobileWrapper>
        <Days />
      </MobileWrapper>
    );
  }

  return <Days />;
};

export default DaysOfWeek;
