import { differenceInDays, format, startOfDay } from 'date-fns';

export const getNightsStay = (
  departureDate?: Date | string,
  returnDate?: Date | string,
): number | undefined => {
  if (departureDate && returnDate) {
    return differenceInDays(
      startOfDay(
        returnDate instanceof Date ? returnDate : new Date(returnDate),
      ),
      startOfDay(
        departureDate instanceof Date ? departureDate : new Date(departureDate),
      ),
    );
  }

  return undefined;
};

export const getSelectHeader = (departureDate?: Date, returnDate?: Date) => {
  const numNightsStay = getNightsStay(departureDate, returnDate);

  if (numNightsStay) {
    return `${numNightsStay} night stay`;
  }

  return departureDate ? 'Select return date' : 'Select departure date';
};

export const getSelectText = (departureDate?: Date, returnDate?: Date) => {
  const formatedDepartureDate =
    departureDate && format(new Date(departureDate), 'iii, d MMM yyyy');
  const formatedReturnDate =
    returnDate && format(new Date(returnDate), 'iii, d MMM yyyy');

  if (departureDate && returnDate) {
    return `${formatedDepartureDate} - ${formatedReturnDate}`;
  }
  return departureDate
    ? `${formatedDepartureDate} -`
    : 'Select travel dates for package availability';
};
