import { FC } from 'react';
import { Box, OutlineButton } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { DateRangePickerContext } from '../../contexts/DatePickerContext';

interface Props {
  disabled?: boolean;
  marginBottomOffset?: number;
  onClick?: Function;
}

const MIN_MARGIN_BOTTOM_OFFSET = 20;

const ButtonBox = styled(Box)`
  text-align: center;
  margin-top: ${themeGet('space.3')};
`;

const Button = styled(OutlineButton)`
  width: 100%;
  padding: ${themeGet('components.dateRangePicker.loadMoreButton.paddingY')}
    ${themeGet('components.dateRangePicker.loadMoreButton.paddingX')};
  border-radius: ${themeGet(
    'components.dateRangePicker.loadMoreButton.borderRadius',
  )};
  color: ${themeGet('components.dateRangePicker.loadMoreButton.color')};
  border-color: ${themeGet(
    'components.dateRangePicker.loadMoreButton.borderColor',
  )};
  font-size: ${themeGet('components.dateRangePicker.loadMoreButton.fontSize')};
  font-weight: ${themeGet(
    'components.dateRangePicker.loadMoreButton.fontWeight',
  )};
  letter-spacing: normal;
  line-height: ${themeGet(
    'components.dateRangePicker.loadMoreButton.lineHeight',
  )};
  text-transform: ${themeGet(
    'components.dateRangePicker.loadMoreButton.textTransform',
  )};

  &:not(:hover) {
    color: ${themeGet('components.dateRangePicker.loadMoreButton.color')};
  }

  &:not(:disabled) {
    &:focus,
    &:hover {
      background-color: ${themeGet(
        'components.dateRangePicker.loadMoreButton.backgroundHover',
      )};
      border-color: ${themeGet(
        'components.dateRangePicker.loadMoreButton.borderColorHover',
      )};
      color: ${themeGet(
        'components.dateRangePicker.loadMoreButton.colorHover',
      )};
    }
  }

  &:disabled {
    color: ${themeGet('components.dateRangePicker.loadMoreButton.disabled')};
    border-color: ${themeGet(
      'components.dateRangePicker.loadMoreButton.disabled',
    )};
  }
`;

const LoadMoreButton: FC<Props> = ({
  disabled = false,
  marginBottomOffset = MIN_MARGIN_BOTTOM_OFFSET,
  onClick,
}) => (
  <ButtonBox mb={`${marginBottomOffset - MIN_MARGIN_BOTTOM_OFFSET}px`}>
    {!disabled && (
      <Button data-testid="LOAD_MORE_DATES" onClick={onClick}>
        Load more dates
      </Button>
    )}
  </ButtonBox>
);

export default LoadMoreButton;
