import React from 'react';
import CVPBanner from '../../v2/components/ui/CVPBanner';
import { useAppSettings } from '../AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';

const GlobalCVPBanner = React.memo(({ className }) => {
  const appSettings = useAppSettings();
  const app = useApp();

  const valuePropositions =
    appSettings?.valuePropositions || app?.valuePropositions;

  if (!valuePropositions) {
    return null;
  }

  return (
    <CVPBanner className={className} valuePropositions={valuePropositions} />
  );
});

export default GlobalCVPBanner;
