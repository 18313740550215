import useCalendarAvailability from './useCalendarAvailability';
import { Months } from './useMonths';

interface Props {
  departureDate?: Date | string;
  propertyId?: string;
  months?: Months;
  selectedRoom?: string;
}

const useMinStay = ({
  departureDate,
  propertyId,
  selectedRoom,
  months,
}: Props) => {
  const { calendarAvailability } = useCalendarAvailability({
    propertyId,
    roomTypeId: selectedRoom,
    startDate: months?.startDate,
    endDate: months?.endDate,
  });

  return calendarAvailability?.find(
    ({ date }) => date === departureDate?.toString(),
  )?.minStay;
};

export default useMinStay;
