import { Box, Heading, Text } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { getSelectHeader, getSelectText } from './helpers/getSelectMessages';
const StyledSelectHeader = styled(Heading.h5)`
  font-size: ${themeGet(
    'components.travelDatesCalendar.selectHeader.fontSize',
  )};
  color: ${themeGet('components.travelDatesCalendar.selectHeader.color')};
`;

const StyledSelectText = styled(Text)`
  font-size: ${themeGet('components.travelDatesCalendar.selectText.fontSize')};
  color: ${themeGet('components.travelDatesCalendar.selectText.color')};
`;

interface Props {
  returnDate?: Date;
  departureDate?: Date;
}

const DateRangeMessage = ({ departureDate, returnDate }: Props) => {
  return (
    <Box data-testid={'DATE_RANGE_MESSAGE'}>
      <StyledSelectHeader mb={1}>
        {getSelectHeader(departureDate, returnDate)}
      </StyledSelectHeader>
      <StyledSelectText fontSize="sm">
        {getSelectText(departureDate, returnDate)}
      </StyledSelectText>
    </Box>
  );
};

export default DateRangeMessage;
