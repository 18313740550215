import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Flex, NakedButton, Text } from '@qga/roo-ui/components';
import { parseOccupantCount } from '@experiences-ui/shared/utils/mapOccupants';
import ClickOutside from '@/components/ClickOutside';
import FormControl from '@/components/FormControl';
import IncrementableNumber from '@/components/IncrementableNumber';
import useForm from '@/hooks/useForm';
import { isGT, isLT } from '@/lib/validator';
import Occupants from '@/propTypes/Occupants';
import {
  getWarningMessage,
  isMaxInfantsWarningActive,
  isMaxTravellerWarningActive,
  isTotalTravellersInvalid,
  MAX_INFANTS_MESSAGE,
  MIN_ADULTS_MESSAGE,
} from './validations';
import FormErrorMessage from '@/shared/components/FormErrorMessage';
import RequestCallbackModal from '@/components/RequestCallbackModal';
import Link from '@/v2/components/ui/Link';
import InlineTextButton from '@experiences-ui/shared/v2/components/InlineTextButton';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const PhoneLink = styled(Link.Naked)`
  :focus-visible {
    outline: 2px solid ${themeGet('colors.brand.secondary')};
  }
`;

const TravellersFormDropdown = ({ occupants, onCancel, onSubmit, policy }) => {
  const [isRequestFormOpen, setIsRequestFormOpen] = useState(false);

  const { getValue, handleValueChange, handleSubmit, validate } = useForm(
    occupants,
    (data) => {
      if (isTotalTravellersInvalid(data)) {
        return;
      }

      onSubmit(data);
    },
  );

  const getNumberValue = (key) => parseOccupantCount(getValue(key));

  const adults = getNumberValue('adults');
  const children = getNumberValue('children');
  const infants = getNumberValue('infants');
  const passengers = { adults, children, infants };

  const isMaxTravellers = isMaxTravellerWarningActive(passengers);
  const isMaxInfants = isMaxInfantsWarningActive(passengers);
  const isGroupBookingSize = adults >= 4;

  // Non-blocking warning: Appear BEFORE going into invalid state
  const error = getWarningMessage(passengers);

  const onClickOutside = (event) => {
    if (error) {
      return onCancel();
    }
    handleSubmit(event);
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMinMaxProps = (key) => {
    if (!policy[key]) {
      return {
        minValue: 0,
        maxValue: Number.MAX_SAFE_INTEGER,
      };
    }

    return {
      minValue: policy[key].min,
      maxValue: policy[key].max,
    };
  };

  return (
    <ClickOutside onOutsideClick={onClickOutside}>
      <Box
        width={[null, null, '230px']}
        boxShadow="soft"
        mt="2"
        bg="greys.porcelain"
      >
        <form onSubmit={handleSubmit} data-testid="TRAVELLERS_FORM">
          <Box p="5" borderBottom="1" borderColor="greys.alto">
            <FormControl
              data-testid="ADULTS"
              htmlFor="adults"
              label="Adults"
              mb="5"
            >
              <IncrementableNumber
                id="adults"
                {...getMinMaxProps('adults')}
                value={adults}
                onChange={handleValueChange('adults', [
                  isGT(0),
                  MIN_ADULTS_MESSAGE,
                ])}
                minValue={1}
                disabledDecrement={isMaxInfants}
                disabledIncrement={isMaxTravellers}
              />
            </FormControl>
            <FormControl
              data-testid="CHILDREN"
              htmlFor="children"
              label="Children (2 - 11yrs)"
              mb="5"
            >
              <IncrementableNumber
                id="children"
                {...getMinMaxProps('children')}
                value={children}
                onChange={handleValueChange('children')}
                disabledIncrement={isMaxTravellers}
              />
            </FormControl>
            <FormControl
              data-testid="INFANTS"
              htmlFor="infants"
              label="Infants (< 2yrs)"
              mb="5"
            >
              <IncrementableNumber
                id="infants"
                {...getMinMaxProps('infants')}
                value={infants}
                onChange={handleValueChange('infants', [
                  isLT(adults, true),
                  MAX_INFANTS_MESSAGE,
                ])}
                disabledIncrement={isMaxTravellers || isMaxInfants}
              />
            </FormControl>
            {error && <FormErrorMessage text={error} noArrow />}
            {isGroupBookingSize && (
              <Box data-testid="group-booking" mt="3" p="3" bg="greys.cloud">
                <Text>
                  For group bookings and multiple room reservations call{' '}
                  <PhoneLink href="tel:137066" fontWeight="bold">
                    13 70 66
                  </PhoneLink>{' '}
                  or{' '}
                  <InlineTextButton onClick={() => setIsRequestFormOpen(true)}>
                    <Text textDecoration="underline">request a callback</Text>
                  </InlineTextButton>
                </Text>
              </Box>
            )}
          </Box>
          <Flex p="5" justifyContent="space-between">
            <NakedButton onClick={onCancel}>Cancel</NakedButton>
            <NakedButton
              data-testid="SUBMIT"
              color="brand.primary"
              type="submit"
            >
              Done
            </NakedButton>
          </Flex>
        </form>
      </Box>
      {isRequestFormOpen && (
        <RequestCallbackModal onClose={() => setIsRequestFormOpen(false)} />
      )}
    </ClickOutside>
  );
};

const PolicyItemPropType = {
  min: PropTypes.number,
  max: PropTypes.number,
};

const PolicyPropType = {
  adults: PropTypes.shape(PolicyItemPropType),
  children: PropTypes.shape(PolicyItemPropType),
  infants: PropTypes.shape(PolicyItemPropType),
};

TravellersFormDropdown.defaultProps = {
  policy: {},
};

TravellersFormDropdown.propTypes = {
  occupants: PropTypes.shape(Occupants).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  policy: PropTypes.shape(PolicyPropType),
};

export default TravellersFormDropdown;
