import { MAX_TRAVELLERS_COUNT } from '@/constants';

const MAX_TRAVELLERS_MESSAGE =
  'A maximum of 9 travellers can be booked at a time.';
export const MIN_ADULTS_MESSAGE = 'You must select at least 1 adult';
export const MAX_INFANTS_MESSAGE =
  'One infant (< 2 years) per adult, can be booked at a time.';

export const isMaxTravellerWarningActive = ({ adults, infants, children }) =>
  adults + children + infants >= MAX_TRAVELLERS_COUNT;

export const isMaxInfantsWarningActive = ({ adults, infants }) =>
  infants >= adults;

export const getWarningMessage = (passengers) => {
  return (
    (isMaxTravellerWarningActive(passengers) && MAX_TRAVELLERS_MESSAGE) ||
    (isMaxInfantsWarningActive(passengers) && MAX_INFANTS_MESSAGE)
  );
};

// Error Validation - Errors are blocking and appear when in an invalid state.
export const isTotalTravellersInvalid = ({ adults, infants, children }) =>
  adults + children + infants > MAX_TRAVELLERS_COUNT;
