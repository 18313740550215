import { parseISO, startOfDay } from 'date-fns';

const toDateObject = (date: string) => {
  const parsed = parseISO(date);

  if (isNaN(parsed.getTime())) {
    throw 'Invalid Date';
  }

  return startOfDay(parsed);
};

export default toDateObject;
