import type { RenderProps } from 'dayzed';

import { createContext } from 'react';

interface DatePickerContextProps {
  blackoutDates: any;
  endDate: Date | undefined;
  getBackProps: (
    month: number,
    year: number,
    index?: number,
  ) => Record<string, any>;
  getDateProps: RenderProps['getDateProps'];
  getForwardProps: () => Record<string, any>;
  monthsToDisplay: number;
  potentialEndDate: Date | undefined;
  setPotentialEndDate: (date?: Date) => any;
  startDate: Date | undefined;
  clickedDate: Date | undefined;
  showTooltip: boolean;
  setShowTooltip: (show: boolean) => any;
  setClickedDate: (date?: Date) => any;
  minStay: number | undefined;
  isCheckoutOnly: boolean;
}

const DatePickerContext = createContext({
  monthsToDisplay: 2,
} as DatePickerContextProps);

export default DatePickerContext;
