import { innerJoin } from 'ramda';
import { useMemo } from 'react';
import { ORIGINS } from '@/constants';

const useOrigins = (originCodes) => {
  const origins = useMemo(
    () =>
      innerJoin(
        (city, originCode) => city.code === originCode,
        ORIGINS,
        originCodes,
      ).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    [originCodes],
  );

  return origins;
};

export default useOrigins;
