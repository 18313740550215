import { useEffect, useMemo, useState } from 'react';
import { differenceInCalendarMonths } from 'date-fns';
import useBreakpoints, { isScreen } from '../../../hooks/useBreakpoints';
import { getDateRange } from '../helpers/getDateRange';

export interface Months {
  startDate: Date;
  endDate: Date;
}

interface Props {
  minDate?: Date;
  maxDate?: Date;
  returnDate?: Date | string;
}

const DESKTOP_MONTHS_TO_DISPLAY = 2;
const MOBILE_MONTHS_TO_DISPLAY = 4;

const doDatesDiffer = (date?: Date, dateToCompare?: Date) =>
  date?.toDateString() !== dateToCompare?.toDateString();

const useMonths = ({ minDate, maxDate, returnDate }: Props) => {
  const dateRange = useMemo(
    () => getDateRange({ minDate, maxDate }),
    [maxDate, minDate],
  );

  const monthsInRange = useMemo(() => {
    const { min, max } = dateRange;
    return differenceInCalendarMonths(max, min) + 1;
  }, [dateRange]);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');

  const [months, setMonths] = useState<Months>();
  const [monthsToDisplay, setMonthsToDisplay] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (smallScreen === true) {
      if (returnDate) {
        const numOfRequiredMonths =
          differenceInCalendarMonths(new Date(returnDate), new Date()) + 1;

        setMonthsToDisplay(
          numOfRequiredMonths > MOBILE_MONTHS_TO_DISPLAY
            ? numOfRequiredMonths
            : MOBILE_MONTHS_TO_DISPLAY,
        );
      } else {
        setMonthsToDisplay(MOBILE_MONTHS_TO_DISPLAY);
      }
    } else if (smallScreen === false) {
      setMonthsToDisplay(DESKTOP_MONTHS_TO_DISPLAY);
    }

    setMonths(undefined);
  }, [smallScreen, returnDate]);

  const handleOnMonthsChanged = ({ startDate, endDate }: Months) => {
    if (
      !(
        doDatesDiffer(months?.startDate, startDate) ||
        doDatesDiffer(months?.endDate, endDate)
      )
    ) {
      return;
    }

    setMonths({
      startDate,
      endDate,
    });
  };

  const loadMoreMonths = () => {
    if (monthsInRange % 2 === 1 && monthsToDisplay === monthsInRange - 1) {
      setMonthsToDisplay(monthsInRange);
    } else if (monthsToDisplay && monthsToDisplay < monthsInRange) {
      setMonthsToDisplay(monthsToDisplay + MOBILE_MONTHS_TO_DISPLAY);
    }
  };

  return {
    dateRange,
    months,
    monthsInRange,
    monthsToDisplay,
    handleOnMonthsChanged,
    loadMoreMonths,
  };
};

export default useMonths;
