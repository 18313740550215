import { useCallback, useState } from 'react';

export type UseControllableStateProps<T> = {
  value?: T;
  defaultValue?: T;
  onChange?: (val: T) => void;
};

const useControllableState = <T>(
  props: UseControllableStateProps<T> | void,
) => {
  const { value, defaultValue, onChange } = props || {};
  const isControlled = value !== undefined;
  const [internalValue, setInternalValue] = useState(defaultValue);
  const derivedValue = isControlled ? value : internalValue;

  const setValue = useCallback(
    (val: T) => {
      onChange?.(val);

      if (!isControlled) {
        setInternalValue(val);
      }
    },
    [isControlled, onChange],
  );

  return [derivedValue, setValue] as const;
};

export default useControllableState;
