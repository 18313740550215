import React from 'react';
import PropTypes from 'prop-types';
import ProcuredOffer from '@experiences-ui/shared/propTypes/ProcuredOffer';
import ProcuredOfferContext from './ProcuredOfferContext';

const ProcuredOfferProvider = ({ value, children }) => {
  return (
    <ProcuredOfferContext.Provider value={value}>
      {children}
    </ProcuredOfferContext.Provider>
  );
};

ProcuredOfferProvider.defaultProps = {
  value: undefined,
};

ProcuredOfferProvider.propTypes = {
  value: PropTypes.shape(ProcuredOffer),
  children: PropTypes.node.isRequired,
};

export default ProcuredOfferProvider;
