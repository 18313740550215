import { addDays, eachDayOfInterval, isAfter, isBefore } from 'date-fns';
import getAvailableReturnFlightDates from '../getAvailableReturnFlightDates';

interface Props {
  departureDate: Date;
  maxDate: Date;
  flightBlackoutDates: Date[];
  propertyBlackoutDates: Date[];
}

const sort = (dates: Date[]) =>
  Array.from(dates).sort(
    (date, nextDate) => date.valueOf() - nextDate.valueOf(),
  );

const getPropertyBlackoutDatesWithoutAvailableFlightDates = ({
  departureDate,
  maxDate,
  flightBlackoutDates,
  propertyBlackoutDates,
}: Props) => {
  const availableFlightDates = sort(
    getAvailableReturnFlightDates({
      blackoutDates: flightBlackoutDates,
      departureDate,
      maxDate: maxDate,
    }),
  ).reverse();

  const withoutAvailableFlightDates = sort(
    propertyBlackoutDates.filter((date) => isAfter(date, departureDate)),
  ).reduce((dates: Date[], propertyBlackoutDate) => {
    for (const availableFlightDate of availableFlightDates) {
      if (availableFlightDate <= propertyBlackoutDate) {
        eachDayOfInterval({
          start: addDays(availableFlightDate, 1),
          end: maxDate,
        }).forEach((additionalBlackoutDate) => {
          if (!dates.includes(additionalBlackoutDate)) {
            dates.push(additionalBlackoutDate);
          }
        });

        break;
      }
    }

    return dates;
  }, []);

  // Prevents unavailable property dates before selected departure from being selected,
  // given selecting a date prior to departure resets the calendar and unavailable property dates cannot be selected
  const propertyBlackoutDatesBeforeDeparture = propertyBlackoutDates.filter(
    (date) => isBefore(date, departureDate),
  );

  return [
    ...propertyBlackoutDatesBeforeDeparture,
    ...withoutAvailableFlightDates,
  ];
};

export default getPropertyBlackoutDatesWithoutAvailableFlightDates;
