import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, Flex, Text } from '@qga/roo-ui/components';
import type { Option } from '../../types';
import { BlackoutDates } from '../../../types/client/BlackoutDates';
import { ClickOutside } from '../ClickOutside';
import { DateRangePicker } from '../DateRangePicker';
import { useBlackoutDates, useMonths, useMinStay } from './hooks';
import { toDateObject, toDateString } from './helpers/dates';
import {
  Actions,
  CloseButton,
  DateRangeMessage,
  FooterWrapper,
  RoomFilter,
  Wrapper,
} from './components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

const CampaignMessage = styled(Box)`
  background-color: ${themeGet('colors.background.page')};
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

interface Props {
  blackoutDates: BlackoutDates;
  campaignHeader?: ReactNode;
  campaignMessage?: string;
  date: string | Date; // Should just be a Date
  departureDate?: string | Date; // Should just be a Date
  maxDate?: Date;
  minDate?: Date;
  minimumNights?: number;
  onClearDates: () => void;
  onChange: ({
    departureDate,
    returnDate,
  }: {
    departureDate: string | Date; // Should just be a Date
    returnDate: string | Date; // Should just be a Date
  }) => void;
  onSubmit: (selectedRoom?: string) => void;
  tandcs: ReactNode;
  returnDate?: string | Date; // Should just be a Date
  submitText?: string;
  propertyId?: string;
  roomTypes?: Array<Option>;
  roomId?: string;
  brand: 'jetstar' | 'qantas';
}

// TODO remove once we've decided on date props as strings or date objects
// We ought to be passing Date objects rather than a mix of Dates or strings
const toDate = (date?: string | Date) => {
  if (!date) {
    return;
  }

  if (typeof date === 'string') {
    return toDateObject(date);
  }

  return date;
};

const T_AND_C_OFFSET = 56;
// This needs to be calculated, perhaps with listeners for resize for example
const DEVICE_NAV_BAR_OFFSET = 60;

const TravelDatesCalendar = ({
  blackoutDates,
  campaignHeader,
  campaignMessage,
  date,
  departureDate,
  maxDate,
  minDate,
  minimumNights,
  onClearDates,
  onChange,
  onSubmit,
  tandcs,
  returnDate,
  submitText = 'Done',
  propertyId,
  roomTypes,
  roomId,
  brand,
}: Props) => {
  const {
    handleOnMonthsChanged,
    months,
    monthsToDisplay,
    dateRange,
    loadMoreMonths,
  } = useMonths({
    minDate,
    maxDate,
  });

  const campaignAndFiltersRef = useRef<HTMLElement>(null);
  const [campaignAndFiltersHeight, setCampaignAndFiltersHeight] = useState(0);
  const [selectedRoom, setSelectedRoom] = useState<string | undefined>(roomId);

  const departureDateObject = toDate(departureDate);
  const returnDateObject = toDate(returnDate);

  const minStay = useMinStay({
    departureDate,
    propertyId,
    selectedRoom,
    months,
  });

  const { propertyBlackoutDates, parsedBlackoutDates, isAvailabilityLoading } =
    useBlackoutDates({
      blackoutDates,
      departureDate: departureDateObject,
      returnDate: returnDateObject,
      minimumNights,
      propertyId,
      selectedRoom,
      months,
      dateRange,
    });

  useEffect(() => {
    if (!campaignAndFiltersRef.current) return;
    setCampaignAndFiltersHeight(
      campaignAndFiltersRef.current.offsetHeight +
        DEVICE_NAV_BAR_OFFSET +
        (!!tandcs ? T_AND_C_OFFSET : 0),
    );
  }, [tandcs]);

  const handleOnRoomChanged = (roomid: string) => {
    setSelectedRoom(roomid);
    onClearDates();
  };

  return (
    <ClickOutside onOutsideClick={() => onSubmit(selectedRoom)}>
      <Box mt={[0, null, 1]} data-testid="TRAVEL_DATES_CALENDAR">
        <Wrapper
          width={[null, null, '52.75rem']}
          height={['100vh', null, 'inherit']}
          brand={brand}
          data-testid="DATE_RANGE_FORM"
        >
          <Box minHeight={['36px', null, 'unset']} ref={campaignAndFiltersRef}>
            <CloseButton onClick={() => onSubmit(selectedRoom)} />
            {campaignHeader}
            {campaignMessage && (
              <CampaignMessage px={[3, null, 8]} py={[3, null, 2]}>
                <Text>{campaignMessage}</Text>
              </CampaignMessage>
            )}
            <Flex
              pb={[0, null, 1]}
              justifyContent="space-between"
              flexDirection={['column', null, 'row']}
              p={[brand === 'jetstar' ? 4 : 3, null, 8]}
            >
              <Box>
                <DateRangeMessage
                  returnDate={returnDateObject}
                  departureDate={departureDateObject}
                />
              </Box>
              {roomTypes && (
                <Box>
                  <RoomFilter
                    selectedRoom={selectedRoom}
                    onSelectedRoom={handleOnRoomChanged}
                    roomTypes={roomTypes}
                    brand={brand}
                  />
                </Box>
              )}
            </Flex>
          </Box>
          <DateRangePicker
            brand={brand}
            isLoading={isAvailabilityLoading}
            blackoutDates={parsedBlackoutDates}
            propertyBlackoutDates={propertyBlackoutDates}
            date={date ? toDate(new Date(date)) : undefined}
            endDate={returnDate ? toDate(new Date(returnDate)) : undefined}
            maxDate={dateRange.max}
            minDate={dateRange.min}
            monthsToDisplay={monthsToDisplay}
            onRangeChange={(range) => {
              return onChange({
                departureDate: range.startDate
                  ? toDateString(range.startDate)
                  : '',
                returnDate: range.endDate ? toDateString(range.endDate) : '',
              });
            }}
            onMonthsChanged={handleOnMonthsChanged}
            startDate={
              departureDate ? toDate(new Date(departureDate)) : undefined
            }
            minStay={minStay}
            loadMoreMonths={loadMoreMonths}
            heightOffset={campaignAndFiltersHeight}
          />
          <FooterWrapper brand={brand} border={!!tandcs}>
            {tandcs}
            <Actions
              onReset={onClearDates}
              onSubmit={() => onSubmit(selectedRoom)}
              submitText={submitText}
              resetText={'Clear dates'}
            />
          </FooterWrapper>
        </Wrapper>
      </Box>
    </ClickOutside>
  );
};

export default TravelDatesCalendar;
