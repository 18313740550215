import { eachDayOfInterval, format } from 'date-fns';
import { ISO_DATE_ONLY_FORMAT } from '../../../../constants';
import type { CommonApi } from '../../../../services/types';

const hasAllAvailabilityForSelectedRange = ({
  start,
  end,
  calendarAvailability,
}: {
  start?: Date;
  end?: Date;
  calendarAvailability: CommonApi.ParsedResponse.CalendarAvailability[];
}) => {
  if (!start || !end) {
    return false;
  }

  const requestedDateRange = eachDayOfInterval({ start, end }).map((date) =>
    format(date, ISO_DATE_ONLY_FORMAT),
  );

  const persistedDateRange = calendarAvailability.map(
    (availability) => availability.date,
  );

  return requestedDateRange.every((date) => persistedDateRange.includes(date));
};

export default hasAllAvailabilityForSelectedRange;
