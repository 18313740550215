import { format } from 'date-fns';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../constants';
import { Dates } from '../../../../../../types/client/BlackoutDates';

const toDatesObject = (dates: Array<Date | string>) =>
  dates.sort().reduce((datesObject: Dates, date: Date | string) => {
    const formatted =
      date instanceof Date ? format(date, ISO_DATE_ONLY_FORMAT) : date;
    return {
      ...datesObject,
      [formatted]: true,
    };
  }, {});

export default toDatesObject;
