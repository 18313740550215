import { format } from 'date-fns';

const getDateRangeInputValue = (departureDate, returnDate) => {
  const datePlaceholder = 'Select';

  const formattedDepartureDate = departureDate
    ? format(new Date(departureDate), 'iii, d MMM yyyy')
    : datePlaceholder;

  const formattedReturnDate = returnDate
    ? format(new Date(returnDate), 'iii, d MMM yyyy')
    : datePlaceholder;

  if (departureDate || returnDate) {
    return `${formattedDepartureDate} - ${formattedReturnDate}`;
  }

  return '';
};

export default getDateRangeInputValue;
