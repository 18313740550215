import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { css } from '@emotion/core';

const SearchFormControlContainer = styled(Box)`
  outline: 2px solid transparent;

  ${(props) =>
    props.customBorderColor &&
    css`
      outline-color: ${themeGet(props.customBorderColor)(props)};
    `}
  ${(props) =>
    props.hasError &&
    css`
      outline-color: ${themeGet('colors.ui.error')(props)};
    `}
  
  @supports not selector(:has(*)) {
    &:focus-within {
      outline-color: ${themeGet('colors.brand.secondary')};
    }
  }

  @supports selector(:has(*)) {
    &:focus-within:not(:has(input:disabled)) {
      outline-color: ${themeGet('colors.brand.secondary')};
    }
  }
`;

const SearchFormControl = ({
  label,
  children,
  htmlFor,
  hasError,
  customBorderColor,
  ...props
}) => (
  <>
    <Box color="greys.charcoal" fontSize="base" width="100%" pb="1">
      <label htmlFor={htmlFor}>{label}</label>
    </Box>
    <SearchFormControlContainer
      tabIndex={-1}
      hasError={hasError}
      customBorderColor={customBorderColor}
      height="48px"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </SearchFormControlContainer>
  </>
);

SearchFormControl.defaultProps = {
  hasError: false,
  customBorderColor: undefined,
};

SearchFormControl.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  customBorderColor: PropTypes.string,
  ...Box.propTypes,
};

export default SearchFormControl;
