import styled from '@emotion/styled';
import { Global } from '@emotion/core';
import { Flex } from '@qga/roo-ui/components';
import { useEffect, useRef } from 'react';

interface Props {
  scrollToMonth?: string;
  mobileScrollHeight?: number;
  setMobileScrollHeight: Function;
  children: React.ReactNode;
}

const MOBILE_SCROLL_OFFSET = 420;

const Scroll = styled(Flex)`
  overflow-y: scroll;
  padding: 0 20.5px 0 20.5px;
`;

const MobileContainer: React.FC<Props> = ({
  scrollToMonth,
  mobileScrollHeight,
  setMobileScrollHeight,
  children,
}) => {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (scrollToMonth && ref?.current) {
      const monthToScrollTo = document?.getElementById(scrollToMonth);
      if (monthToScrollTo) {
        monthToScrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrollToMonth) {
      if (!ref.current?.scrollHeight || ref.current.scrollTop > 0) {
        return;
      }

      if (mobileScrollHeight) {
        ref.current.scrollTo(
          0,
          ref.current.scrollHeight - mobileScrollHeight - MOBILE_SCROLL_OFFSET,
        );
      } else {
        setMobileScrollHeight(ref.current.scrollHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileScrollHeight, setMobileScrollHeight]);

  return (
    <Scroll
      justifyContent="space-between"
      alignContent="flex-start"
      flexDirection="column"
      mb={32}
      ref={ref}
    >
      <Global
        styles={{
          body: {
            overflow: 'hidden',
          },
        }}
      />
      {children}
    </Scroll>
  );
};

export default MobileContainer;
