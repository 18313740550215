import { useMemo } from 'react';
import {
  BlackoutDates,
  Dates,
} from '../../../../../types/client/BlackoutDates';

interface Props {
  blackoutDates: BlackoutDates;
  propertyBlackoutDates?: Dates;
}

const useFlightDepartureBlackoutDates = ({
  blackoutDates,
  propertyBlackoutDates,
}: Props) => {
  const departureBlackoutDates = useMemo(
    () => ({
      ...propertyBlackoutDates,
      ...blackoutDates?.departure,
    }),
    [blackoutDates?.departure, propertyBlackoutDates],
  );

  return departureBlackoutDates;
};

export default useFlightDepartureBlackoutDates;
