import { Flex, Icon, NakedButton, Box } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import React from 'react';
import Input from '../Input';

const IncrementableNumber = ({
  value,
  onChange,
  minValue,
  maxValue,
  disabledDecrement,
  disabledIncrement,
  ...props
}) => (
  <Box>
    <Flex border="1" bg="white" data-testid="WRAPPER" borderColor="transparent">
      <NakedButton
        data-testid="MIN"
        p="3"
        onClick={() => onChange(value - 1)}
        disabled={disabledDecrement || value <= minValue}
      >
        <Icon
          name="remove"
          color={minValue === value ? 'greys.alto' : 'default'}
        />
      </NakedButton>
      <Flex justifyContent="center" flex="1" alignItems="center">
        <Input
          data-testid="INPUT"
          textAlign="center"
          border={0}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          value={value}
          readOnly
        />
      </Flex>
      <NakedButton
        data-testid="ADD"
        p="3"
        onClick={() => onChange(value + 1)}
        disabled={disabledIncrement || value >= maxValue}
      >
        <Icon
          name="add"
          color={maxValue === value ? 'greys.alto' : 'default'}
        />
      </NakedButton>
    </Flex>
  </Box>
);

IncrementableNumber.defaultProps = {
  value: 0,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
};

IncrementableNumber.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default IncrementableNumber;
