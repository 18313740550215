import { isAfter, isBefore } from 'date-fns';
import { BlackoutDates } from '../../../../../../../types/client/BlackoutDates';
import { fromDatesObject, toDatesObject } from '../../../../helpers/dates';
import { getMinimumNightsBlackoutDates } from '../../../../helpers/getMinimumNightsBlackoutDates';
import { getMaximumStayBlackoutDates } from '../../../../helpers/getMaximumStayBlackoutDates';

interface Props {
  blackoutDates: BlackoutDates;
  departureDate: Date;
  minimumNights?: number;
  maxDate: Date;
}

interface HelperProps {
  departureDate: Date;
  blackoutDates: BlackoutDates;
}

const getReturnFlightBlackoutDatesWithoutFlightsPriorToDeparture = ({
  departureDate,
  blackoutDates,
}: HelperProps) => {
  const returnBlackoutDates = fromDatesObject(blackoutDates?.return);

  return toDatesObject(
    returnBlackoutDates.filter((date) => isAfter(date, departureDate)),
  );
};

// Prevents unavailable flight departure dates before selected departure from being selected,
// given selecting a date prior to departure resets the calendar and unavailable flight departure dates cannot be selected
const getDepartureBlackoutDatesPriorToDeparture = ({
  departureDate,
  blackoutDates,
}: HelperProps) => {
  const departureBlackoutDates = fromDatesObject(blackoutDates?.departure);

  return toDatesObject(
    departureBlackoutDates.filter((date) => isBefore(date, departureDate)),
  );
};

const getReturnFlightBlackoutDates = ({
  blackoutDates,
  departureDate,
  minimumNights,
  maxDate,
}: Props) =>
  fromDatesObject({
    ...getReturnFlightBlackoutDatesWithoutFlightsPriorToDeparture({
      departureDate,
      blackoutDates,
    }),
    ...getDepartureBlackoutDatesPriorToDeparture({
      departureDate,
      blackoutDates,
    }),
    ...getMinimumNightsBlackoutDates({
      departureDate,
      minimumNights,
    }),
    ...getMaximumStayBlackoutDates({
      departureDate,
      maxDate,
    }),
  });

export default getReturnFlightBlackoutDates;
