import { addDays, eachDayOfInterval, parseISO, startOfDay } from 'date-fns';
import asDateStrings from '../../../../helpers/dates/asDateStrings';

interface Props {
  blackoutDates: Date[];
  departureDate: Date;
  maxDate: Date;
}

const getAvailableReturnFlightDates = ({
  blackoutDates,
  departureDate,
  maxDate,
}: Props) => {
  const blackoutDateStrings = asDateStrings(blackoutDates);

  const availableDates = asDateStrings(
    eachDayOfInterval({
      start: startOfDay(addDays(departureDate, 1)),
      end: startOfDay(maxDate),
    }),
  ).reduce((dates: Date[], date: string) => {
    if (!blackoutDateStrings.includes(date)) {
      dates.push(startOfDay(parseISO(date)));
    }

    return dates;
  }, []);

  return availableDates;
};

export default getAvailableReturnFlightDates;
