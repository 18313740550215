import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  format,
  isAfter,
} from 'date-fns';
import { ISO_DATE_ONLY_FORMAT } from '../../../../constants';
import BFF from '../../../../../services/BFF';
import type { CommonApi } from '../../../../services/types';
import { hasAllAvailabilityForSelectedRange } from '../helpers';

interface Props {
  propertyId?: string;
  roomTypeId?: string;
  startDate?: Date;
  endDate?: Date;
}

const MAX_DAYS_PER_REQUEST = 62;

const splitIntoBatches = ({ start, end }: { start: Date; end: Date }) => {
  if (differenceInDays(end, start) < MAX_DAYS_PER_REQUEST) {
    return [{ start, end }];
  }

  const daysRequested = eachDayOfInterval({ start, end });

  const batches = Array.from(
    { length: Math.ceil(daysRequested.length / MAX_DAYS_PER_REQUEST) },
    (_element, index) =>
      daysRequested.slice(
        index * MAX_DAYS_PER_REQUEST,
        index * MAX_DAYS_PER_REQUEST + MAX_DAYS_PER_REQUEST,
      ),
  ).map((batch) => ({
    start: batch[0],
    end: addDays(batch[batch.length - 1], 1),
  }));

  return batches;
};

const useCalendarAvailability = ({
  propertyId,
  roomTypeId,
  startDate,
  endDate,
}: Props) => {
  const [isAvailabilityLoading, setIsAvailabilityLoading] = useState(false);
  const [cachedRoomTypeId, setCachedRoomTypeId] = useState<string | undefined>(
    roomTypeId,
  );

  const [calendarAvailability, setPropertyCalendarAvailability] = useState<
    CommonApi.ParsedResponse.CalendarAvailability[]
  >([]);

  useEffect(() => {
    if (![propertyId, roomTypeId].some((id) => id) || !startDate || !endDate)
      return;

    const today = new Date();
    const start = isAfter(today, startDate) ? today : startDate;
    const end = addDays(endDate, 1);

    if (roomTypeId !== cachedRoomTypeId) {
      setCachedRoomTypeId(roomTypeId);
      setPropertyCalendarAvailability([]);
    }

    if (
      hasAllAvailabilityForSelectedRange({
        start,
        end,
        calendarAvailability,
      })
    ) {
      return;
    }

    setIsAvailabilityLoading(true);

    let _calendarAvailability: CommonApi.ParsedResponse.CalendarAvailability[] =
      [...calendarAvailability];

    splitIntoBatches({ start, end }).forEach(({ start, end }) => {
      (roomTypeId
        ? BFF.getCalendarAvailabilityForRoomType()(
            roomTypeId,
            format(start, ISO_DATE_ONLY_FORMAT),
            format(end, ISO_DATE_ONLY_FORMAT),
          )
        : BFF.getCalendarAvailabilityForProperty()(
            propertyId,
            format(start, ISO_DATE_ONLY_FORMAT),
            format(end, ISO_DATE_ONLY_FORMAT),
          )
      )
        .then(
          (
            res: AxiosResponse<CommonApi.ParsedResponse.CalendarAvailability[]>,
          ) => {
            if (Array.isArray(res?.data)) {
              const additionalFromResponse = res.data.filter(
                (availableDate) =>
                  !_calendarAvailability.find(
                    (existingDate) => existingDate.date === availableDate.date,
                  ),
              );

              _calendarAvailability = [
                ..._calendarAvailability,
                ...additionalFromResponse,
              ];
            }
          },
        )
        .finally(() => {
          setPropertyCalendarAvailability(_calendarAvailability);
          setIsAvailabilityLoading(false);
        });
    });
    // calendarAvailability is not a triggering dependency of the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, roomTypeId, startDate, endDate, cachedRoomTypeId]);

  return { calendarAvailability, isAvailabilityLoading };
};

export default useCalendarAvailability;
