import { useContext } from 'react';
import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import type { DateObj } from 'dayzed';
import { Box, NakedButton, Tooltip, Flex, Text } from '@qga/roo-ui/components';
import { format, isSameDay, isWithinInterval } from 'date-fns';
import { throttle } from 'lodash';
import { SCREEN_SIZE } from '../../../../constants';
import useBreakpoints, { isScreen } from '../../../../hooks/useBreakpoints';
import { DateRangePickerContext } from '../../contexts/DatePickerContext';

const ANIMATION_DURRATION = 300;
const ANIMATION_DELAY = 5;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

interface StyledDayProps {
  animationDelay?: number;
  isStartDate?: boolean;
  isEndDate?: boolean;
  isWithinInterval?: boolean;
  isPotentialEndDate?: boolean;
}

const StyledDay = styled(Box)`
  isolation: isolate;
  position: relative;
  width: calc(100% / 7);
  border: ${themeGet('components.dateRangePicker.day.borderWidth')} solid
    transparent;

  ::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${(props: StyledDayProps) =>
    props.animationDelay &&
    css`
      opacity: 0;
      animation: ${fadeIn} linear ${ANIMATION_DURRATION}ms;
      animation-fill-mode: forwards;

      ${SCREEN_SIZE.MD} {
        animation-delay: ${props.animationDelay}ms;
      }
    `}

  ${(props: StyledDayProps) =>
    (props.isEndDate || props.isStartDate || props.isWithinInterval) &&
    css`
      ::before {
        background-color: ${themeGet(
          'components.dateRangePicker.rangeSelected.background',
        )(props)};
      }
    `}

  ${(props: StyledDayProps) =>
    props.isStartDate &&
    css`
      :hover,
      :focus {
        border-color: transparent;
      }

      ::before {
        border-top-left-radius: ${themeGet(
          'components.dateRangePicker.day.borderRadius',
        )(props)};
        border-bottom-left-radius: ${themeGet(
          'components.dateRangePicker.day.borderRadius',
        )(props)};
      }
    `}
  
  ${(props: StyledDayProps) =>
    (props.isPotentialEndDate || props.isEndDate) &&
    css`
      ::before {
        border-top-right-radius: ${themeGet(
          'components.dateRangePicker.day.borderRadius',
        )(props)};
        border-bottom-right-radius: ${themeGet(
          'components.dateRangePicker.day.borderRadius',
        )(props)};
    `}
`;

interface StyledButtonProps {
  isSelected?: boolean;
  isPotentialEndDate?: boolean;
}

const StyledButton = styled(NakedButton)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: ${themeGet('components.dateRangePicker.day.paddingBottom')};
  border: 1px solid transparent;
  border-radius: ${themeGet('components.dateRangePicker.day.borderRadius')};
  font-size: ${themeGet('components.dateRangePicker.day.fontSize')};
  font-weight: ${themeGet('components.dateRangePicker.day.fontWeight')};
  z-index: 1;

  :focus {
    outline: 0;
  }

  :not(:disabled) {
    &:hover {
      border: ${themeGet(
          'components.dateRangePicker.potentialDaySelected.borderWidth',
        )}
        solid
        ${themeGet(
          'components.dateRangePicker.potentialDaySelected.borderColor',
        )};
    }
  }

  :disabled {
    font-weight: normal;
    cursor: initial;
    text-decoration: line-through;
  }

  :disabled {
    &,
    :focus,
    :hover {
      color: ${themeGet('components.dateRangePicker.unavailableDay.color')};
    }
  }

  ${(props: StyledButtonProps) =>
    props.isSelected &&
    css`
      background-color: ${themeGet(
        'components.dateRangePicker.daySelected.background',
      )(props)};

      color: ${themeGet('components.dateRangePicker.daySelected.color')(props)};

      :disabled {
        opacity: 1;
        color: ${themeGet('components.dateRangePicker.daySelected.color')(
          props,
        )};
        font-weight: ${themeGet('components.dateRangePicker.day.fontWeight')(
          props,
        )};
        text-decoration: unset;
        :focus,
        :hover {
          color: ${themeGet('components.dateRangePicker.daySelected.color')(
            props,
          )};
          font-weight: ${themeGet('components.dateRangePicker.day.fontWeight')(
            props,
          )};
          background-color: ${themeGet(
            'components.dateRangePicker.daySelected.background',
          )(props)};
        }
      }
    `}

  ${(props: StyledButtonProps) =>
    props.isPotentialEndDate &&
    css`
      border: ${themeGet(
          'components.dateRangePicker.potentialDaySelected.borderWidth',
        )(props)}
        solid
        ${themeGet(
          'components.dateRangePicker.potentialDaySelected.borderColor',
        )(props)};
    `}
`;

const StyledFlex = styled(Flex)`
  top: ${themeGet('components.dateRangePicker.tooltip.top')};
`;

interface DayProps {
  dateObj: DateObj | '';
  monthIndex?: number;
}

const Day = ({ dateObj, monthIndex = 0 }: DayProps) => {
  const {
    blackoutDates,
    endDate,
    getDateProps,
    potentialEndDate,
    setPotentialEndDate,
    startDate,
    setClickedDate,
    clickedDate,
    setShowTooltip,
    showTooltip,
    minStay,
    isCheckoutOnly,
  } = useContext(DateRangePickerContext);
  const breakpoints = useBreakpoints();
  const isSmallScreen = isScreen(breakpoints)('xs', 'sm');

  if (!dateObj) return <StyledDay />;

  const { date } = dateObj;
  const isStartDate = startDate && isSameDay(startDate, date);
  const isEndDate = endDate && isSameDay(endDate, date);
  const isPotentialEndDate =
    potentialEndDate && isSameDay(potentialEndDate, date);
  const isSelected = !isCheckoutOnly && (isStartDate || isEndDate);
  const isClickedDate = clickedDate && isSameDay(clickedDate, date);

  const monthDelay = ANIMATION_DURRATION * monthIndex;
  const animationDelay = ANIMATION_DELAY * date.getDate() + monthDelay;

  const getIsWithinInterval = () => {
    if (startDate && endDate) {
      return isWithinInterval(date, { start: startDate, end: endDate });
    }

    if (startDate && potentialEndDate) {
      return isWithinInterval(date, {
        start: startDate,
        end: potentialEndDate,
      });
    }

    return false;
  };

  const dateProps = getDateProps({
    dateObj: {
      ...dateObj,
      selectable:
        dateObj.selectable && !blackoutDates[format(date, 'yyyy-MM-dd')],
    },
  });

  const handlePotentialEndDate = () => {
    if (!startDate || endDate || date < startDate) {
      return;
    }

    throttle(setPotentialEndDate)(date);
  };

  const handleClearPotentialEndDate = () => {
    setShowTooltip(false);
    setClickedDate(undefined);
    setPotentialEndDate();
  };

  return (
    <StyledDay
      isEndDate={isEndDate}
      isPotentialEndDate={isPotentialEndDate}
      isStartDate={isStartDate}
      isWithinInterval={getIsWithinInterval()}
      animationDelay={animationDelay}
    >
      {showTooltip && isClickedDate && (
        <StyledFlex
          position="absolute"
          justifyContent="center"
          left="0"
          right="0"
          marginBottom="0"
          zIndex="popup"
        >
          <Tooltip colorMode="white" pointerDirection="bottom" boxShadow="soft">
            <Text color="text.body">
              {isCheckoutOnly
                ? 'Checkout only'
                : `${minStay}-night ${isSmallScreen ? 'min' : 'minimum'}`}
            </Text>
          </Tooltip>
        </StyledFlex>
      )}
      <StyledButton
        data-testid={`DAY_${date.getTime()}`}
        isPotentialEndDate={isPotentialEndDate}
        isSelected={isSelected}
        onBlur={handleClearPotentialEndDate}
        onFocus={handlePotentialEndDate}
        onMouseOut={handleClearPotentialEndDate}
        onMouseOver={handlePotentialEndDate}
        {...dateProps}
      >
        {date.getDate()}
      </StyledButton>
    </StyledDay>
  );
};

export default Day;
