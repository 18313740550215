import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Box } from '@qga/roo-ui/components';
import { getTravellersCount } from '@experiences-ui/shared/utils';
import Dropdown from '@experiences-ui/shared/v2/components/Dropdown';
import TravellersFormDropdown from '@/components/TravellersFormDropdown';
import Occupants from '@/propTypes/Occupants';
import onA11yKeyDown from '@/utils/onA11yKeyDown';
import FormErrorMessage from '@/shared/components/FormErrorMessage';
import SearchFormControl from '../SearchFormControl';
import SearchDropdownInput from '../SearchDropdownInput';
import useControllableState from '@experiences-ui/shared/v2/hooks/useControllableState';

const Travellers = ({
  occupants,
  onSubmit,
  hasError,
  customBorderColor,
  isOpen: isOpenProp,
  onOpen,
  onClose,
  icon,
  isDisabled,
  className,
}) => {
  // Open State
  const onChange = (newIsOpen) => (newIsOpen ? onOpen?.() : onClose?.());
  const [isOpen, setIsOpen] = useControllableState({
    defaultValue: false,
    value: isOpenProp,
    onChange,
  });

  const travellers = getTravellersCount(occupants);

  return (
    <Flex className={className ?? ''} flexDirection="column" flex="1">
      <SearchFormControl
        data-testid="TRAVELLERS"
        htmlFor="travellers"
        label="Travellers"
        height="48px"
        hasError={hasError}
        customBorderColor={customBorderColor}
      >
        <Dropdown
          isOpen={isOpen}
          toggle={(ref) => (
            <Box ref={ref}>
              <SearchDropdownInput
                data-testid="TRAVELLERS_INPUT"
                id="travellers"
                onClick={() => setIsOpen(true)}
                onKeyDown={onA11yKeyDown('select', (event) => {
                  event.preventDefault();
                  setIsOpen(true);
                })}
                readOnly
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(isOpen && { borderColor: 'ui.info' })}
                value={
                  travellers > 0 ? pluralize('traveller', travellers, true) : ''
                }
                placeholder="Select travellers"
                icon={icon}
                disabled={isDisabled}
              />
            </Box>
          )}
          content={
            <TravellersFormDropdown
              occupants={occupants}
              onCancel={() => setIsOpen(false)}
              onSubmit={(data) => {
                onSubmit(data);
                setIsOpen(false);
              }}
            />
          }
        />
      </SearchFormControl>
      {hasError && <FormErrorMessage text="Required" />}
    </Flex>
  );
};

Travellers.defaultProps = {
  value: '',
  hasError: false,
};

Travellers.propTypes = {
  occupants: PropTypes.shape(Occupants).isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Travellers;
