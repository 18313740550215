import { useContext } from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import type { Calendar } from 'dayzed';
import {
  Box,
  Flex,
  Icon,
  NakedButton,
  Text,
  Button,
} from '@qga/roo-ui/components';
import { DateRangePickerContext } from '../../contexts/DatePickerContext';
import { SCREEN_SIZE } from '../../../../constants';
import { DaysOfWeek, Week } from '../';

const MONTH_NAMES_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const StyledMonthName = styled(Text)`
  color: ${themeGet('components.dateRangePicker.monthName.color')};
  font-size: ${themeGet('components.dateRangePicker.monthName.fontSize')};
  text-transform: ${themeGet(
    'components.dateRangePicker.monthName.textTransform',
  )};
`;

const StyledBackButton = styled(NakedButton)`
  display: none;
  background: ${themeGet('colors.white')};
  border-radius: 50%;
  color: ${themeGet('colors.secondary')};

  :disabled,
  :disabled:focus,
  :disabled:hover {
    color: ${themeGet('components.dateRangePicker.disabledMonthNav')};
  }

  :hover,
  :focus {
    color: ${themeGet('components.dateRangePicker.monthNavHover')};
  }

  ${SCREEN_SIZE.MD} {
    display: block;
  }
`;
const StyledForwardButton = styled(NakedButton)`
  display: none;
  background: ${themeGet('colors.white')};
  border-radius: 50%;
  color: ${themeGet('colors.brand.primary')};

  :disabled,
  :disabled:focus,
  :disabled:hover {
    color: ${themeGet('components.dateRangePicker.disabledMonthNav')};
  }

  :hover,
  :focus {
    color: ${themeGet('components.dateRangePicker.monthNavHover')};
  }

  ${SCREEN_SIZE.MD} {
    display: block;
  }
`;

interface Props {
  calendar: Calendar;
  index: number;
  isLoading?: boolean;
  showDaysOfWeek?: boolean;
}

const Month = ({
  calendar,
  index,
  isLoading,
  showDaysOfWeek = true,
}: Props) => {
  const { getBackProps, getForwardProps, monthsToDisplay } = useContext(
    DateRangePickerContext,
  );

  return isLoading ? null : (
    <Box>
      <Box py="3" position="relative">
        <Box mb="0" textAlign="center">
          <StyledMonthName fontWeight={700}>
            {`${MONTH_NAMES_SHORT[calendar.month]} ${calendar.year}`}
          </StyledMonthName>
        </Box>

        {index === 0 && (
          <Flex
            alignItems="center"
            bottom="0"
            left={[0, null, '-48px']}
            position="absolute"
            top="0"
          >
            <StyledBackButton
              data-testid="MONTH_BACK"
              {...getBackProps(calendar.month, calendar.year, index)}
            >
              <Icon size={32} color="inherit" name="chevronLeft" />
            </StyledBackButton>
          </Flex>
        )}

        {index === monthsToDisplay - 1 && (
          <Flex
            position="absolute"
            alignItems="center"
            top="0"
            bottom="0"
            right={[0, null, '-48px']}
          >
            <StyledForwardButton
              data-testid="MONTH_FORWARD"
              {...getForwardProps()}
            >
              <Icon size={32} color="inherit" name="chevronRight" />
            </StyledForwardButton>
          </Flex>
        )}
      </Box>
      {showDaysOfWeek && <DaysOfWeek />}
      <Box>
        {calendar.weeks.map((week, i) => (
          <Week key={i} week={week} monthIndex={index} />
        ))}
      </Box>
    </Box>
  );
};

export default Month;
