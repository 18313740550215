import type { ReactNode, SyntheticEvent } from 'react';

import { useEffect } from 'react';

import { onA11yKeyDown } from '../../utils/onA11yKeyDown';

const preventInsideClickEventPropagatingToWindow = (event: SyntheticEvent) =>
  event.stopPropagation();

interface Props {
  children: ReactNode;
  onOutsideClick: () => void;
}

const ClickOutside = ({ children, onOutsideClick }: Props) => {
  useEffect(() => {
    const buttonA11yHandler = onA11yKeyDown('button', onOutsideClick);

    window.addEventListener('click', onOutsideClick);
    window.addEventListener('keydown', buttonA11yHandler);

    return () => {
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener('keydown', buttonA11yHandler);
    };
  }, [onOutsideClick]);

  return (
    <div
      onClick={preventInsideClickEventPropagatingToWindow}
      onKeyDown={onA11yKeyDown('button', (event) => event.stopPropagation())}
    >
      {children}
    </div>
  );
};

export default ClickOutside;
