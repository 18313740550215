import { useState } from 'react';
import Router from 'next/router';
import { Box, Text, Flex } from '@qga/roo-ui/components';
import Filter from '../../../Filter';
import Select from '../../../Select';
import type { Option } from '../../../../types';
import userInteractionEvent from '../../../../../../shared/utils/userInteractionEvent';
import getPageNameFromPath from '../../../../../../shared/utils/getPageNameFromPath';
import dataLayer from '../../../../../../shared/utils/dataLayer';

interface Props {
  selectedRoom?: string;
  onSelectedRoom: Function;
  roomTypes: Array<Option>;
  brand?: 'jetstar' | 'qantas';
}

const RoomFilter = ({
  selectedRoom,
  onSelectedRoom,
  roomTypes,
  brand,
}: Props) => {
  const jetstar = brand === 'jetstar';
  const [allrooms, setAllrooms] = useState(!selectedRoom);

  const handleSelectedChange = (value: string) => {
    if (value) {
      onSelectedRoom(value);
      setAllrooms(false);
    }

    dataLayer.push(
      userInteractionEvent(
        getPageNameFromPath(Router.asPath),
        `Room filter clicked`,
        'Availability Calendar',
      ),
    );
  };

  const handleClickAllroomsOptions = () => {
    setAllrooms(true);
    onSelectedRoom(undefined);

    dataLayer.push(
      userInteractionEvent(
        getPageNameFromPath(Router.asPath),
        `View all room button selected`,
        'Availability Calendar',
      ),
    );
  };

  return (
    <>
      {jetstar && (
        <Box pt={[2, null, 0]}>
          <Text fontWeight="bold" fontSize="xs">
            Room availability
          </Text>
        </Box>
      )}
      <Flex
        alignItems={[null, null, 'center']}
        flexDirection="row"
        height={['inherit', null, '58px']}
        data-testid="ROOM_FILTER"
      >
        <Box mr={2} pt={[2, null, 0]}>
          <Filter
            active={allrooms}
            onClick={handleClickAllroomsOptions}
            height={jetstar ? '40px' : '48px'}
          >
            <Text
              fontWeight="inherit"
              fontSize={jetstar ? '14px' : 'inherit'}
              lineHeight="24px"
            >
              {jetstar ? 'All room options' : 'View all rooms'}
            </Text>
          </Filter>
        </Box>
        <Box
          pt={[2, null, 0]}
          position="relative"
          width={jetstar ? '100%' : '190px'}
        >
          <Select
            value={selectedRoom}
            options={roomTypes}
            placeholder={jetstar ? 'Select a room' : 'Filter by room'}
            ellipsis
            menuAlign="right"
            onSelectedChange={handleSelectedChange}
            selectSize={jetstar ? 'sm' : 'md'}
          />
        </Box>
      </Flex>
    </>
  );
};

export default RoomFilter;
