import { useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import Dayzed from 'dayzed';
import { endOfDay, lastDayOfMonth, startOfDay } from 'date-fns';
import { Box, LoadingIndicator } from '@qga/roo-ui/components';
import useBreakpoints, { isScreen } from '../../hooks/useBreakpoints';
import isBelowMinStay from './helpers/isBelowMinStay';
import { DateRangePickerContext } from './contexts/DatePickerContext';
import {
  DaysOfWeek,
  Month,
  LoadingSpinner,
  LoadingWrapper,
  Wrapper,
  MobileContainer,
  LoadMoreButton,
} from './components';
import { toDateString } from '../TravelDatesCalendar/helpers/dates';
import hasBlackoutDatesWithinMinStay from './helpers/hasBlackoutDatesWithinMinStay';

interface Props {
  isLoading?: boolean;
  blackoutDates?: any;
  propertyBlackoutDates?: any;
  children?: (months: Array<{ key: string; node: ReactNode }>) => ReactNode;
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  monthsToDisplay?: number;
  minStay?: number;
  onRangeChange: ({
    startDate,
    endDate,
  }: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => void;
  onMonthsChanged: Function;
  loadMoreMonths: Function;
  brand?: 'jetstar' | 'qantas';
  heightOffset?: number;
}

const DateRangePicker = ({
  isLoading = false,
  blackoutDates = {},
  propertyBlackoutDates = {},
  children,
  date = new Date(),
  startDate,
  endDate,
  minDate = new Date(),
  maxDate,
  monthsToDisplay,
  minStay,
  onRangeChange,
  onMonthsChanged,
  loadMoreMonths,
  brand = 'qantas',
  heightOffset,
}: Props) => {
  // TODO: Fix console errors related to Dayzed
  const [potentialEndDate, setPotentialEndDate] = useState<Date | undefined>();
  const [clickedDate, setClickedDate] = useState<Date | undefined>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [calendarToStartWith, setCalendarToStartWith] = useState<
    string | undefined
  >(undefined);
  const [mobileScrollHeight, setMobileScrollHeight] = useState<
    number | undefined
  >();
  const [isCheckoutOnly, setIsCheckoutOnly] = useState(false);

  const isNonSelectableDate =
    !endDate &&
    !!minStay &&
    !!clickedDate &&
    hasBlackoutDatesWithinMinStay(clickedDate, minStay, propertyBlackoutDates);

  const isStartDateNotSelected = !(
    !!clickedDate &&
    startDate &&
    clickedDate > startDate &&
    !endDate
  );

  useEffect(() => {
    if (startDate) {
      const idToFind = `${startDate.getMonth()}${startDate.getFullYear()}`;
      setCalendarToStartWith(idToFind);
    }
  }, [startDate]);

  useEffect(() => {
    if (!!minStay && minStay > 1) {
      setShowTooltip(
        !startDate || isBelowMinStay(minStay, startDate, clickedDate),
      );
    }
  }, [minStay, clickedDate, startDate]);

  useEffect(() => {
    if (isStartDateNotSelected && !!minStay) {
      setIsCheckoutOnly(isNonSelectableDate);
    }
  }, [isStartDateNotSelected, minStay, isNonSelectableDate]);

  useEffect(() => {
    if (isStartDateNotSelected && isNonSelectableDate) {
      onRangeChange({
        startDate: undefined,
        endDate: undefined,
      });
    }
  }, [isStartDateNotSelected, isNonSelectableDate, onRangeChange]);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');

  if (!monthsToDisplay) return null;

  const handleOnDateSelected = (newDate: Date) => {
    setPotentialEndDate(undefined);
    setClickedDate(newDate);

    if (startDate && newDate > startDate && !endDate) {
      if (isBelowMinStay(minStay!, startDate, newDate)) {
        return;
      }

      onRangeChange({
        startDate,
        endDate: newDate,
      });

      return;
    }

    onRangeChange({
      startDate: newDate,
      endDate: undefined,
    });
  };

  const getCustomizeDisabledForBack = (
    minDate: Date,
    month: number,
    year: number,
  ) => {
    if (!minDate) {
      return false;
    }

    return minDate.getMonth() === month && minDate.getFullYear() === year;
  };

  return (
    <Dayzed
      date={!smallScreen ? date : new Date()}
      firstDayOfWeek={1}
      minDate={minDate && startOfDay(minDate)}
      maxDate={maxDate && endOfDay(maxDate)}
      monthsToDisplay={monthsToDisplay}
      onDateSelected={(dateObj) => {
        const { date: newDate } = dateObj;
        handleOnDateSelected(newDate);
      }}
      render={({ calendars, getBackProps, getForwardProps, getDateProps }) => (
        <DateRangePickerContext.Provider
          value={{
            blackoutDates,
            endDate,
            getBackProps: (month, year) => {
              const disabled = getCustomizeDisabledForBack(
                minDate,
                month,
                year,
              );

              return {
                ...getBackProps({ calendars }),
                disabled,
              };
            },
            getDateProps,
            getForwardProps: () => getForwardProps({ calendars }),
            monthsToDisplay,
            potentialEndDate,
            setPotentialEndDate,
            startDate,
            clickedDate,
            setClickedDate,
            showTooltip,
            setShowTooltip,
            minStay,
            isCheckoutOnly,
          }}
        >
          <Wrapper>
            {(() => {
              const months = calendars.map((calendar, i) => ({
                key: `${calendar.month}${calendar.year}`,
                node: (
                  <Month
                    calendar={calendar}
                    index={i}
                    isLoading={isLoading}
                    showDaysOfWeek={!smallScreen}
                  />
                ),
              }));

              if (onMonthsChanged && calendars.length > 0) {
                onMonthsChanged({
                  startDate: calendars[0].firstDayOfMonth,
                  endDate:
                    calendars[calendars.length > 1 ? calendars.length - 1 : 0]
                      .lastDayOfMonth,
                });
              }

              if (children) {
                return children(months);
              }

              if (isLoading) {
                return (
                  <LoadingWrapper
                    data-testid={`${brand.toUpperCase()}_LOADING`}
                  >
                    {brand === 'jetstar' ? (
                      <LoadingSpinner />
                    ) : (
                      <LoadingIndicator />
                    )}
                  </LoadingWrapper>
                );
              }

              const wrappedMonths = months.map(({ key, node }) => (
                <Box
                  width={['100%', null, 'calc(50% - 25px)']}
                  height={['auto', null, '24rem']}
                  key={key}
                  id={key}
                >
                  {node}
                </Box>
              ));

              if (smallScreen) {
                const disableLoadMoreButton =
                  calendars?.length > 1 && maxDate
                    ? toDateString(
                        calendars[calendars.length - 1].lastDayOfMonth,
                      ) == toDateString(lastDayOfMonth(maxDate))
                    : isLoading;

                return (
                  <>
                    <DaysOfWeek fixed />
                    <MobileContainer
                      scrollToMonth={calendarToStartWith}
                      mobileScrollHeight={mobileScrollHeight}
                      setMobileScrollHeight={setMobileScrollHeight}
                    >
                      {wrappedMonths}
                      <LoadMoreButton
                        onClick={loadMoreMonths}
                        disabled={disableLoadMoreButton}
                        marginBottomOffset={heightOffset}
                      />
                    </MobileContainer>
                  </>
                );
              }

              return wrappedMonths;
            })()}
          </Wrapper>
        </DateRangePickerContext.Provider>
      )}
    />
  );
};

export default DateRangePicker;
