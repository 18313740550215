import styled from '@emotion/styled';
import { Flex, Icon } from '@qga/roo-ui/components';
import { space, themeGet } from 'styled-system';
import Input from '../Input';
import PropTypes from 'prop-types';

const SearchInput = styled(Input)`
  ${space};
  cursor: pointer;

  &::placeholder:not(:disabled) {
    color: ${themeGet('colors.greys.charcoal')};
  }
`;

const StyledIcon = styled(Icon)`
  pointer-events: none;
  position: absolute;
  right: 12px;
`;

const SearchDropdownInput = ({ icon, ...props }) => (
  <Flex alignItems="center" height="100%" position="relative">
    <SearchInput
      px={4}
      border="none"
      backgroundColor="transparent"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
    {icon ?? <StyledIcon name="expandMore" size={24} />}
  </Flex>
);

SearchDropdownInput.defaultProps = {
  ...Input.defaultProps,
};

SearchDropdownInput.propTypes = {
  ...Input.propTypes,
  icon: PropTypes.node,
};

export default SearchDropdownInput;
