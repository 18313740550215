import { DropdownPosition, MenuDimensions, OffsetPosition } from './types';

export const getPositionProps = ({
  position,
  offsetPosition,
  menuDimensions,
  smallScreen,
}: {
  position: DropdownPosition;
  offsetPosition?: OffsetPosition;
  menuDimensions?: MenuDimensions;
  smallScreen?: boolean;
}) => {
  const screenWidth = document.body.scrollWidth;
  const positions = { top: offsetPosition?.bottom };

  if (smallScreen) {
    return {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    };
  }

  switch (position) {
    case 'BOTTOM_WITH_OFFSET':
      return {
        ...positions,
        left: offsetPosition?.left,
        right: offsetPosition?.right,
      };
    case 'BOTTOM':
      return {
        ...positions,
        left: 0,
        right: 0,
      };
    case 'BOTTOM_LEFT':
      return {
        ...positions,
        left: offsetPosition?.left,
      };
    case 'BOTTOM_RIGHT':
      if (!offsetPosition || !menuDimensions) {
        return positions;
      }

      return screenWidth - offsetPosition.right <= menuDimensions.width
        ? { ...positions, left: 0 }
        : {
            ...positions,
            right: offsetPosition.right,
          };
    default:
      return positions;
  }
};
