import { format, addDays } from 'date-fns';

const hasBlackoutDatesWithinMinStay = (
  date: Date,
  minStay: number,
  blackoutDates: any,
) => {
  if (minStay < 2) {
    return false;
  }

  const dates = [];
  for (let i = 1; i < minStay; i += 1) {
    dates.push(!!blackoutDates[format(addDays(date, i), 'yyyy-MM-dd')]);
  }

  return dates.some(Boolean);
};

export default hasBlackoutDatesWithinMinStay;
