import { differenceInDays } from 'date-fns';

const isBelowMinStay = (
  minStay: number,
  startDate?: Date,
  selectedDate?: Date,
) =>
  Boolean(
    startDate &&
      selectedDate &&
      differenceInDays(selectedDate, startDate) < minStay - 1,
  );

export default isBelowMinStay;
