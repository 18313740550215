import { Dates } from '../../../../../../types/client/BlackoutDates';
import toDateObject from '../toDateObject';

const fromDatesObject = (dates?: Dates | null) =>
  dates
    ? Object.keys(dates)
        .sort()
        .reduce((datesAsArray: Date[], date) => {
          const asDate = toDateObject(date);
          if (dates[date] === true && !!asDate) {
            datesAsArray.push(asDate);
          }

          return datesAsArray;
        }, [])
    : [];

export default fromDatesObject;
