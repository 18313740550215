import { addDays, eachDayOfInterval, toDate } from 'date-fns';
import { toDatesObject } from '../dates';

interface Props {
  departureDate: Date;
  minimumNights?: number;
}

const getMinimumNightsBlackoutDates = ({
  departureDate,
  minimumNights,
}: Props) => {
  if (!minimumNights || minimumNights <= 1) {
    return {};
  }

  const start = toDate(new Date(departureDate));

  return toDatesObject(
    eachDayOfInterval({
      start: addDays(start, 1),
      end: addDays(start, minimumNights - 1),
    }),
  );
};

export default getMinimumNightsBlackoutDates;
