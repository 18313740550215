import { addDays, eachDayOfInterval, toDate } from 'date-fns';
import { MAX_STAY } from '../../../../constants';
import { toDatesObject } from '../dates';

interface Props {
  departureDate: Date;
  maxDate: Date;
}

const getMaximumStayBlackoutDates = ({ departureDate, maxDate }: Props) => {
  const start = addDays(toDate(new Date(departureDate)), MAX_STAY);

  if (maxDate < start) {
    return {};
  }

  return toDatesObject(
    eachDayOfInterval({
      start,
      end: maxDate,
    }),
  );
};

export default getMaximumStayBlackoutDates;
