import { Box, Text, Icon } from '@qga/roo-ui/components';
import PropTypes from 'prop-types';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import LinkButton from '@/components/LinkButton';
import TermAndConditionModal from '../TermsAndConditions/TermAndConditionModal';

const TermsAndConditions = ({
  selectedPackageOption,
  inclusionData,
  terms,
}) => {
  if (!!selectedPackageOption) {
    return (
      <Box px={[4, null, 8]} py={[3, null, 6]}>
        <ToggleContent
          toggle={(show) => (
            <LinkButton hover noPadding onClick={() => show()}>
              <Text color="greys.charcoal" fontSize="sm">
                Package details and terms{' '}
                <Icon color="greys.charcoal" name="info" size={17} />
              </Text>
            </LinkButton>
          )}
          content={(closeModal) => (
            <TermAndConditionModal
              inclusionData={inclusionData}
              onClose={closeModal}
              termsAndCondition={terms}
            />
          )}
        />
      </Box>
    );
  }
  return <></>;
};

TermAndConditionModal.propTypes = {
  selectedPackageOption: PropTypes.object,
  inclusionData: PropTypes.shape({
    inclusions: PropTypes.array.isRequired,
    propertyName: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }),
  terms: PropTypes.string.isRequired,
};

export default TermsAndConditions;
