import { format } from 'date-fns';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../constants';

const toDateString = (date: Date) => {
  if (isNaN(date.getTime())) {
    throw 'Invalid Date';
  }

  return format(date, ISO_DATE_ONLY_FORMAT);
};

export default toDateString;
