import { FC } from 'react';
import { Box, Icon } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { SCREEN_SIZE } from '../../../../constants';

const Container = styled(Box)`
  position: fixed;
  top: ${themeGet('components.travelDatesCalendar.closeButton.padding')};
  right: ${themeGet('components.travelDatesCalendar.closeButton.padding')};
  display: block;

  ${SCREEN_SIZE.MD} {
    display: none;
  }
`;

const CloseButton: FC<{ onClick: Function }> = ({ onClick }) => (
  <Container onClick={onClick}>
    <Icon size={32} color="#707070" name="close" />
  </Container>
);

export default CloseButton;
