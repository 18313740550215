import { useMemo } from 'react';
import { addMonths, eachDayOfInterval, format } from 'date-fns';
import { ISO_DATE_ONLY_FORMAT } from '../../../../constants';
import { Months } from '../useMonths';
import { CommonApi } from '../../../../services/types';
import { Dates } from '../../../../../types/client/BlackoutDates';
import isAvailabilityRequestForPropertyOrRoom from '../../helpers/isAvailabilityRequestForPropertyOrRoom';
import { toDatesObject } from '../../helpers/dates';

interface Props {
  propertyId?: string;
  selectedRoom?: string;
  months?: Months;
  calendarAvailability: CommonApi.ParsedResponse.CalendarAvailability[];
}

const today = new Date();
const plus2Months = addMonths(today, 2);

const usePropertyBlackoutDates = ({
  propertyId,
  selectedRoom,
  months,
  calendarAvailability,
}: Props) => {
  const propertyBlackoutDates: Dates = useMemo(() => {
    if (
      !isAvailabilityRequestForPropertyOrRoom({
        propertyId,
        roomTypeId: selectedRoom,
        calendarAvailability,
      })
    ) {
      return {};
    }

    const daysWithoutAvailability = eachDayOfInterval({
      start: months?.startDate || today,
      end: months?.endDate || plus2Months,
    }).filter(
      (date) =>
        !calendarAvailability.some(
          (ava) =>
            ava.date === format(date, ISO_DATE_ONLY_FORMAT) &&
            ava.available === true,
        ),
    );

    return toDatesObject(daysWithoutAvailability);
  }, [months, calendarAvailability, propertyId, selectedRoom]);

  return propertyBlackoutDates;
};

export default usePropertyBlackoutDates;
