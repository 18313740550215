import { useMemo } from 'react';
import { BlackoutDates } from '../../../../types/client/BlackoutDates';
import { DateRange } from './types';
import { Months } from './useMonths';
import useCalendarAvailability from './useCalendarAvailability';
import usePropertyBlackoutDates from './usePropertyBlackoutDates';
import useDepartureBlackoutDates from './useDepartureBlackoutDates';
import useReturnBlackoutDates from './useReturnBlackoutDates';

interface Props {
  blackoutDates: BlackoutDates;
  departureDate?: Date;
  returnDate?: Date;
  minimumNights?: number;
  propertyId?: string;
  selectedRoom?: string;
  months?: Months;
  dateRange: DateRange;
}

const useBlackoutDates = ({
  blackoutDates,
  departureDate,
  returnDate,
  minimumNights,
  propertyId,
  selectedRoom,
  months,
  dateRange,
}: Props) => {
  const { calendarAvailability, isAvailabilityLoading } =
    useCalendarAvailability({
      propertyId,
      roomTypeId: selectedRoom,
      startDate: months?.startDate,
      endDate: months?.endDate,
    });

  const propertyBlackoutDates = usePropertyBlackoutDates({
    propertyId,
    selectedRoom,
    months,
    calendarAvailability,
  });

  const departureBlackoutDates = useDepartureBlackoutDates({
    blackoutDates,
    propertyBlackoutDates,
  });

  const returnBlackoutDates = useReturnBlackoutDates({
    departureDate,
    returnDate,
    propertyBlackoutDates,
    blackoutDates,
    minimumNights,
    dateRange,
    propertyId,
    selectedRoom,
  });

  const parsedBlackoutDates = useMemo(() => {
    if (departureDate && !returnDate) {
      return returnBlackoutDates;
    }

    return departureBlackoutDates;
  }, [departureBlackoutDates, departureDate, returnBlackoutDates, returnDate]);

  return { propertyBlackoutDates, parsedBlackoutDates, isAvailabilityLoading };
};

export default useBlackoutDates;
