import styled from '@emotion/styled';
import { Box } from '@qga/roo-ui/components';
import { SCREEN_SIZE } from '../../../../constants';

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 180px);

  ${SCREEN_SIZE.MD} {
    width: 842px;
    height: 384px;
  }
`;

export default LoadingWrapper;
