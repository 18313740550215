import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Root = styled.div`
  height: 48px;
  width: 48px;
  animation: ${spin} 1s linear infinite;
`;

const LoadingSpinner = () => {
  return (
    <Root>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
      >
        <path
          fill="url(#a)"
          d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24a3.429 3.429 0 0 0 6.857 0c0-9.468 7.675-17.143 17.143-17.143S41.143 14.532 41.143 24 33.468 41.143 24 41.143A3.429 3.429 0 1 0 24 48Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1="48"
            x2="0"
            y1="48"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF5115" />
            <stop offset="1" stopColor="#FF8600" />
          </linearGradient>
        </defs>
      </svg>
    </Root>
  );
};

export default LoadingSpinner;
