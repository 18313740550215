import React, { useRef, useState, useCallback } from 'react';
import { Box } from '@qga/roo-ui/components';
import Portal from '../Portal';
import throttle from '../../utils/throttle';
import useBreakpoints, { isScreen } from '../../hooks/useBreakpoints';
import { DropdownProps, MenuDimensions, OffsetPosition } from './types';
import { getPositionProps } from './utils';

const Dropdown = ({
  position = 'BOTTOM_LEFT',
  fullscreenOnMobile = false,
  isOpen,
  toggle,
  content,
}: DropdownProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const [offsetPosition, setOffsetPosition] = useState<OffsetPosition>();
  const [menuDimensions, setMenuDimensions] = useState<MenuDimensions>();
  const handleResizeRef = useRef<() => void>();
  const positionEffect = useCallback((element: HTMLElement) => {
    if (!element && handleResizeRef.current) {
      window.removeEventListener('resize', handleResizeRef.current);
      return;
    }

    handleResizeRef.current = throttle(() => {
      if (!ref.current) {
        return;
      }

      const boundingClientRect = ref.current.getBoundingClientRect();
      const eleBoundingClientRect = element.getBoundingClientRect();

      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const screenWidth = document.body.scrollWidth;

      setMenuDimensions({
        height: eleBoundingClientRect.height,
        width: eleBoundingClientRect.width,
      });

      setOffsetPosition({
        top: scrollTop + boundingClientRect.top,
        bottom: scrollTop + boundingClientRect.bottom,
        left: scrollLeft + boundingClientRect.left,
        right: screenWidth - boundingClientRect.right,
        height: boundingClientRect.height,
        width: boundingClientRect.width,
      });
    });

    if (handleResizeRef.current) {
      handleResizeRef.current();
      window.addEventListener('resize', handleResizeRef.current);
    }
  }, []);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');
  const shouldTakeUpWholeScreen = smallScreen && fullscreenOnMobile;

  return (
    <>
      {toggle(ref)}
      {isOpen && (
        <Portal selector="portal-root">
          <Box
            data-testid="DROPDOWN_ITEM"
            position={shouldTakeUpWholeScreen ? 'fixed' : 'absolute'}
            height={shouldTakeUpWholeScreen ? '100vh' : 'inherit'}
            zIndex={smallScreen ? 'popup' : 1}
            ref={positionEffect}
            {...getPositionProps({
              position,
              offsetPosition,
              menuDimensions,
              smallScreen: shouldTakeUpWholeScreen,
            })}
          >
            {content}
          </Box>
        </Portal>
      )}
    </>
  );
};

export default Dropdown;
