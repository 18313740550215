import { Button } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';

const ConfirmButton = styled(Button)`
  width: ${themeGet('components.travelDatesCalendar.actionConfirm.width')};
  padding: ${themeGet('components.travelDatesCalendar.actionConfirm.paddingY')}
    ${themeGet('components.travelDatesCalendar.actionConfirm.paddingX')};
  border-radius: ${themeGet(
    'components.travelDatesCalendar.actionConfirm.borderRadius',
  )};
  background-color: ${themeGet(
    'components.travelDatesCalendar.actionConfirm.background',
  )};
  color: ${themeGet('components.travelDatesCalendar.actionConfirm.color')};
  font-size: ${themeGet(
    'components.travelDatesCalendar.actionConfirm.fontSize',
  )};
  font-weight: ${themeGet(
    'components.travelDatesCalendar.actionConfirm.fontWeight',
  )};
  letter-spacing: normal;
  line-height: ${themeGet(
    'components.travelDatesCalendar.actionConfirm.lineHeight',
  )};
  text-transform: ${themeGet(
    'components.travelDatesCalendar.actionConfirm.textTransform',
  )};

  :not(:disabled) {
    &:focus,
    &:hover {
      background-color: ${themeGet(
        'components.travelDatesCalendar.actionConfirm.backgroundHover',
      )};
      color: ${themeGet(
        'components.travelDatesCalendar.actionConfirm.colorHover',
      )};
    }
  }
`;

export default ConfirmButton;
