import { Flex } from '@qga/roo-ui/components';

interface Props {
  children: React.ReactNode;
}

const Wrapper: React.FC<Props> = ({ children }) => (
  <Flex
    px={[0, null, 20]}
    py={[0, null, 5]}
    my={[2, null, 0]}
    justifyContent="space-between"
    flexDirection={['column', null, 'row']}
    height="inherit"
    position="relative"
  >
    {children}
  </Flex>
);

export default Wrapper;
