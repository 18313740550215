import styled from '@emotion/styled';
import { ComponentProps, KeyboardEvent } from 'react';
import { themeGet } from 'styled-system';
import { Box } from '@qga/roo-ui/components';

export type InlineButtonProps = ComponentProps<typeof Box> & {
  onClick?: () => void;
};

const StyledSpan = styled(Box)`
  display: inline;
  cursor: pointer;

  :focus-visible {
    outline: 2px solid ${themeGet('colors.brand.secondary')};
  }
`;

/**
 * This is a workaround to be able to have a 'button' that behaves like inline text.
 * This is required as an actual button element can never be styled to be inline
 * - it will always behave as inline-block.
 */
const InlineTextButton = ({
  children,
  onClick,
  ...props
}: InlineButtonProps) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLSpanElement>) => {
    const enterOrSpace =
      event.key === 'Enter' || event.key === ' ' || event.key === 'Spacebar';

    if (enterOrSpace) {
      event.preventDefault();
      onClick?.();
    }
  };

  return (
    <StyledSpan
      {...props}
      role="button"
      onClick={onClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}
    >
      {children}
    </StyledSpan>
  );
};

export default InlineTextButton;
