import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { Flex } from '@qga/roo-ui/components';
import { SCREEN_SIZE } from '../../../../constants';
import { ConfirmButton, ResetButton } from './components';

interface Props {
  onReset: Function;
  onSubmit: Function;
  submitText: string;
  resetText: string;
}

const StyledActions = styled(Flex)`
  border-top: ${themeGet('components.travelDatesCalendar.actions.borderWidth')}
    solid ${themeGet('components.travelDatesCalendar.actions.borderColor')};
  width: 100%;
`;

const Actions = ({ onReset, onSubmit, submitText, resetText }: Props) => {
  return (
    <StyledActions px={[4, null, 8]} py="4" justifyContent="space-between">
      <ResetButton onClick={onReset}>{resetText}</ResetButton>
      <ConfirmButton data-testid="SUBMIT" onClick={onSubmit}>
        {submitText}
      </ConfirmButton>
    </StyledActions>
  );
};

export default Actions;
