import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Router from 'next/router';
import { Flex, Box } from '@qga/roo-ui/components';
import useBreakpoints, { isScreen } from '@/hooks/useBreakpoints';
import BlackoutDates from '@/propTypes/BlackoutDates';
import onA11yKeyDown from '@/utils/onA11yKeyDown';
import FormErrorMessage from '@/shared/components/FormErrorMessage';
import SearchFormControl from '../SearchFormControl';
import SearchDropdownInput from '../SearchDropdownInput';
import getDateRangeInputValue from './helpers/getDateRangeInputValue';
import TravelDatesCalendar from '@experiences-ui/shared/v2/components/TravelDatesCalendar';
import usePropertyContent from '@/components/PackagePage/hooks/usePropertyContent';
import Dropdown from '@experiences-ui/shared/v2/components/Dropdown';
import useProcuredOffer from '@/components/PackagePage/hooks/useProcuredOffer';
import userInteractionEvent from '../../../../../libs/shared/utils/userInteractionEvent';
import getPageNameFromPath from '../../../../../libs/shared/utils/getPageNameFromPath';
import dataLayer from '../../../../../libs/shared/utils/dataLayer';
import useDestination from '@/hooks/useDestination';
import useControllableState from '@experiences-ui/shared/v2/hooks/useControllableState';
import { useRoomOptionsData } from '@/v2/components/pages/PackageView/contexts/RoomOptionsContext';
import TermsAndConditions from './TermsAndConditions';

const DateRange = ({
  blackoutDates,
  minimumNights,
  data,
  errorText,
  onDataChange,
  customBorderColor,
  onCalendarFocus,
  isOpen: isOpenProp,
  isDisabled,
  onOpen,
  onClose,
  icon,
  className,
  roomId: roomIdProp,
  placeholder = 'Select dates',
}) => {
  // Open State
  const onChange = (newIsOpen) => (newIsOpen ? onOpen?.() : onClose?.());
  const [isOpen, setIsOpen] = useControllableState({
    defaultValue: false,
    value: isOpenProp,
    onChange,
  });

  const [state, setState] = useState();
  const [rendered, setRendered] = useState(false);
  const [roomId, setRoomId] = useControllableState({
    defaultValue: undefined,
    value: roomIdProp,
  });

  const { departureDate, returnDate } = state || data;

  const destination = useDestination();
  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');
  const dropDownPosition = smallScreen ? 'BOTTOM_WITH_OFFSET' : 'BOTTOM_RIGHT';

  const startDateRef = useRef(departureDate);
  const [startDate, setStartDate] = useState(startDateRef.current);

  const { id: propertyId, name: propertyName } = usePropertyContent() || {};
  const { roomOptions, selectedPackageOption } = useRoomOptionsData() || {};
  const roomTypeOptions = roomOptions?.main?.map((roomOption) => ({
    value: roomOption.roomType.id,
    text: roomOption.roomType.name,
  }));
  const procuredOffer = useProcuredOffer();
  const inclusionData = {
    destination: destination?.title,
    propertyName: propertyName,
    inclusions: selectedPackageOption?.inclusions,
  };

  const [isTravelSelected, setIsTravelSelected] = useState(false);

  useEffect(() => {
    if (!data.originCode || (!destination?.theme && !data.destinationCode)) {
      setIsTravelSelected(false);
    } else {
      setIsTravelSelected(true);
    }
  }, [data.originCode, data.destinationCode, destination]);
  useEffect(() => {
    setRendered(true);
  }, []);

  useEffect(() => {
    if (!!departureDate && !returnDate) {
      dataLayer.push(
        userInteractionEvent(
          getPageNameFromPath(Router.asPath),
          `Check-in ${departureDate}`,
          'Date Calendar',
        ),
      );
    }

    if (!!returnDate) {
      dataLayer.push(
        userInteractionEvent(
          getPageNameFromPath(Router.asPath),
          `Check-out ${returnDate}`,
          'Date Calendar',
        ),
      );
    }
  }, [departureDate, returnDate]);

  const renderTravelDatesCalender = (close) => {
    if (!isTravelSelected) return null;
    const submit = () => {
      dataLayer.push(
        userInteractionEvent(
          getPageNameFromPath(Router.asPath),
          `Apply changes`,
          'Availability Calendar',
        ),
      );

      onDataChange(state);
      setState();
      close();
    };

    const clearDates = () => {
      onDataChange({
        departureDate: undefined,
        returnDate: undefined,
      });
      setState();
    };

    return (
      <TravelDatesCalendar
        blackoutDates={blackoutDates}
        departureDate={departureDate}
        date={startDate}
        minimumNights={minimumNights}
        onClearDates={clearDates}
        onChange={(range) => {
          startDateRef.current = range.departureDate;
          setState(range);
        }}
        onSubmit={submit}
        returnDate={returnDate}
        propertyId={propertyId}
        tandcs={
          <TermsAndConditions
            selectedPackageOption={selectedPackageOption}
            inclusionData={inclusionData}
            terms={procuredOffer?.terms}
          />
        }
        roomTypes={roomTypeOptions}
        roomId={roomId}
        brand="qantas"
      />
    );
  };

  return (
    <Flex
      className={className ?? ''}
      id="date-range"
      data-testid="DATE_RANGE"
      position="relative"
      flexDirection="column"
    >
      <SearchFormControl
        htmlFor="when"
        label="When"
        height="48px"
        hasError={!!errorText}
        customBorderColor={customBorderColor}
        onFocus={() => {
          onCalendarFocus?.(true);
        }}
        onBlur={() => {
          onCalendarFocus?.(false);
        }}
      >
        <Dropdown
          isOpen={isOpen}
          position={dropDownPosition}
          fullscreenOnMobile
          toggle={(ref) => {
            const openWithStartDate = () => {
              setStartDate(startDateRef.current);
              isTravelSelected && setIsOpen(true);
            };

            const clickCheckAvailabilityButtonHandler = (e) => {
              setRoomId(e.detail.roomId);
              isTravelSelected && setIsOpen(true);
            };

            const eventHanlderController = (element) => {
              if (!element) {
                window.removeEventListener(
                  'clickCheckAvailabilityButton',
                  clickCheckAvailabilityButtonHandler,
                );
                return;
              }

              if (rendered)
                window.addEventListener(
                  'clickCheckAvailabilityButton',
                  clickCheckAvailabilityButtonHandler,
                  {
                    once: true,
                  },
                );
            };

            return (
              <Box ref={ref}>
                <Box ref={eventHanlderController}>
                  <SearchDropdownInput
                    data-testid="DATE_RANGE_INPUT"
                    id="when"
                    onClick={openWithStartDate}
                    onKeyDown={onA11yKeyDown('select', (event) => {
                      event.preventDefault();
                      openWithStartDate();
                    })}
                    value={getDateRangeInputValue(departureDate, returnDate)}
                    placeholder={placeholder}
                    icon={icon}
                    disabled={isDisabled}
                    readOnly
                  />
                </Box>
              </Box>
            );
          }}
          content={renderTravelDatesCalender(() => setIsOpen(false))}
        />
      </SearchFormControl>
      {!!errorText && <FormErrorMessage text={errorText} noArrow />}
    </Flex>
  );
};

DateRange.defaultProps = {
  blackoutDates: {},
  minimumNights: 0,
  errorText: '',
  customBorderColor: undefined,
};

DateRange.propTypes = {
  data: PropTypes.shape({
    departureDate: PropTypes.string,
    returnDate: PropTypes.string,
    originCode: PropTypes.string,
    destinationCode: PropTypes.string,
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  minimumNights: PropTypes.number,
  errorText: PropTypes.string,
  blackoutDates: PropTypes.shape(BlackoutDates),
  customBorderColor: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  icon: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  roomId: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DateRange;
