import type { CommonApi } from '../../../../services/types';

interface Props {
  propertyId?: string;
  roomTypeId?: string;
  calendarAvailability?:
    | CommonApi.ParsedResponse.CalendarAvailability[]
    | Date[];
}

const isAvailabilityRequestForPropertyOrRoom = ({
  propertyId,
  roomTypeId,
  calendarAvailability,
}: Props) =>
  [propertyId, roomTypeId].some((id) => id) &&
  !!calendarAvailability &&
  calendarAvailability?.length > 0;

export default isAvailabilityRequestForPropertyOrRoom;
