import { useMemo } from 'react';
import isAvailabilityRequestForPropertyOrRoom from '../../helpers/isAvailabilityRequestForPropertyOrRoom';
import { DateRange } from '../types';
import { fromDatesObject, toDatesObject } from '../../helpers/dates';
import {
  getPropertyBlackoutDatesWithoutAvailableFlightDates,
  getReturnFlightBlackoutDates,
} from './helpers';
import {
  BlackoutDates,
  Dates,
} from '../../../../../types/client/BlackoutDates';

interface Props {
  departureDate?: Date;
  returnDate?: Date;
  propertyBlackoutDates?: Dates;
  blackoutDates: BlackoutDates;
  minimumNights?: number;
  dateRange: DateRange;
  propertyId?: string;
  selectedRoom?: string;
}

const useReturnBlackoutDates = ({
  departureDate,
  returnDate,
  propertyBlackoutDates,
  blackoutDates,
  minimumNights,
  dateRange,
  propertyId,
  selectedRoom,
}: Props) => {
  const returnBlackoutDates = useMemo(() => {
    if (!departureDate || (departureDate && returnDate)) {
      return {};
    }

    const flightBlackoutDateObjects = getReturnFlightBlackoutDates({
      blackoutDates,
      departureDate,
      minimumNights,
      maxDate: dateRange.max,
    });

    const propertyBlackoutDateObjects = propertyBlackoutDates
      ? fromDatesObject(propertyBlackoutDates)
      : [];

    if (
      isAvailabilityRequestForPropertyOrRoom({
        propertyId,
        roomTypeId: selectedRoom,
        calendarAvailability: propertyBlackoutDateObjects,
      })
    ) {
      const propertyBlackoutDatesWithoutAvailableFlightDates =
        getPropertyBlackoutDatesWithoutAvailableFlightDates({
          departureDate,
          maxDate: dateRange.max,
          flightBlackoutDates: flightBlackoutDateObjects,
          propertyBlackoutDates: propertyBlackoutDateObjects,
        });

      return toDatesObject([
        ...flightBlackoutDateObjects,
        ...propertyBlackoutDatesWithoutAvailableFlightDates,
      ]);
    }

    return toDatesObject([
      ...flightBlackoutDateObjects,
      ...propertyBlackoutDateObjects,
    ]);
  }, [
    blackoutDates,
    dateRange.max,
    departureDate,
    minimumNights,
    propertyBlackoutDates,
    returnDate,
    propertyId,
    selectedRoom,
  ]);

  return returnBlackoutDates;
};

export default useReturnBlackoutDates;
