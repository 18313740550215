import { addDays, max, min, startOfDay, toDate } from 'date-fns';

import { BOOKABLE_PERIOD } from '../../../../constants';

interface Props {
  maxDate?: Date;
  minDate?: Date;
}

const getDateRange = ({ maxDate, minDate }: Props) => {
  const d = startOfDay(new Date());

  return {
    min: max([
      ...(minDate ? [toDate(minDate)] : []),
      addDays(d, BOOKABLE_PERIOD.min),
    ]),
    max: min([
      ...(maxDate ? [toDate(maxDate)] : []),
      addDays(d, BOOKABLE_PERIOD.max),
    ]),
  };
};

export default getDateRange;
