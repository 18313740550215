import toDateString from '../toDateString';

const asDateStrings = (dates: Date[]) =>
  Array.from(
    new Set(
      dates
        .map((date) => toDateString(date))
        .filter((date) => date !== undefined),
    ),
  ).sort();

export default asDateStrings;
